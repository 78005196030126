/* eslint-disable func-names */
import React from 'react';
import { $on, $off, $emit } from 'event-bus-e2z';
import { push } from 'react-router-redux';

import {
    TRUID_ADDRESS_MISSING,
    TRUID_SALARY_DATE_MISSING,
} from '../truidConstants';
import { DecisioningResult, ErrorCode, routeName, UpsellingResultStatus } from '../constants';

import { SegmentBusTrackCode } from '../segment-bus/SegmentBusTrackCode';
import { SegmentBusTrackLanding } from '../segment-bus/SegmentBusTrackLanding';
import { SegmentBusTrackFacials } from '../segment-bus/SegmentBusTrackFacials';
import { SegmentBusTrackTruID } from '../segment-bus/SegmentBusTrackTruID';

const timeCached = 3;
const ingoreCached = new Map();

export const useEventBus = (name, callback) => {
    React.useEffect(() => {
        $on(name, callback);
        return function () {
            $off(name, callback);
        };
    }, [name, callback]);

    return { name, callback };
};

export default useEventBus;

export const EventBusName = {
    // TRUID
    TRUID_NOTIFY_REFRESH: 'TRUID_NOTIFY_REFRESH',
    TRUID_NORIFY_RELOAD_IFRAME: 'TRUID_NORIFY_RELOAD_IFRAME',

    TRUID_NOTIFY_ACTION: 'TRUID_NOTIFY_ACTION',
    TRUID_NOTIFY_RESUME_URL: 'TRUID_NOTIFY_RESUME_URL',
    TRUID_SAVE_DELINCE_CODE: 'TRUID_SAVE_DELINCE_CODE',
    TRUID_NOTIFY_G_PROFILE_INCOME_TYPE: 'TRUID_NOTIFY_G_PROFILE_INCOME_TYPE',

    // TRUID_MISSING
    TRUID_ADDRESS_MISSING,
    TRUID_SALARY_DATE_MISSING,

    // other
    SHOW_LOADING_QUICK: 'SHOW_QUICK_LOADING',
    SHOW_LOADING_IN_BACKGROUND: 'SHOW_LOADING_IN_BACKGROUND',
    SHOW_LOADING_MESSAGE: 'SHOW_LOADING_MESSAGE',
    SHOW_CONFIRM_APPLY: 'SHOW_CONFIRM_APPLY',
    NOTIFY_ACCEPT_CONSENT: 'NOTIFY_ACCEPT_CONSENT',
    NOTIFY_ERROR: 'NOTIFY_ERROR',
    NOTIFY_CLEAR_GO_HOME: 'NOTIFY_CLEAR_GO_HOME',
    HIDE_MODAL_COMMON: 'HIDE_MODAL_COMMON',

    NOTIFY_CONFIRM_BEFORE_LEAVE: 'NOTIFY_CONFIRM_BEFORE_LEAVE',

    REFRESH_API_TOKEN: 'REFRESH_API_TOKEN',
    UPDATE_EXACT_SEGMENT_PRODUCT_BY_SAID: 'UPDATE_EXACT_SEGMENT_PRODUCT_BY_SAID',

    // send Google Analytics page
    GA_SEND_PAGE: 'GA_SEND_PAGE',
    MOVE_TO_SCREEN: 'MOVE_TO_SCREEN', // optimize later

    // verify loan
    VERIFY_LOAN_APPLICATION_SAID: 'VERIFY_LOAN_APPLICATION_SAID',
    FETCH_TRUID_MISSING_AFFORDABILITY: 'FETCH_TRUID_MISSING_AFFORDABILITY',
    FETCH_SPRINT_HIVE_AFFORDABILITY: 'FETCH_SPRINT_HIVE_AFFORDABILITY',

    MANDATE_NOTIFY_CONFIRM: 'MANDATE_NOTIFY_CONFIRM',
    MANDATE_NOTIFY_ERROR: 'MANDATE_NOTIFY_ERROR',

    MEDIA_WEBCAM_BLOCK: 'MEDIA_WEBCAM_BLOCK',
    MEDIA_WEBCAM_NO_EXIST: 'MEDIA_WEBCAM_NO_EXIST',

    // facetec
    FACETEC_ASK_CAMERA: 'FACETEC_ASK_CAMERA',
    FACETEC_NOTIFY_ERROR: 'FACETEC_NOTIFY_ERROR',
    FACETEC_NOTIFY_TIMEOUT: 'FACETEC_NOTIFY_TIMEOUT',
    FACETEC_NOTIFY_SUCCESS: 'FACETEC_NOTIFY_SUCCESS',
    FACETEC_REFRESH_API_SESSION: 'FACETEC_REFRESH_API_SESSION',
    FACTECT_REFRESH_API_FETCHING: 'FACTECT_REFRESH_API_FETCHING',

    FACTECT_NOTIFY_INIT_FAIL: 'FACTECT_NOTIFY_INIT_FAIL',
    FACTECT_SDK_START: 'FACTECT_SDK_START',
    FACTECT_SDK_SETUP: 'FACTECT_SDK_SETUP',
    FACTECT_SDK_INTITAL: 'FACTECT_SDK_INTITAL',
    FACTECT_SDK_ERROR: 'FACTECT_SDK_ERROR',
};

export class CommonBusUtils {
    static setAcceptConsents(isAccpeted = false) {
        $emit(EventBusName.NOTIFY_ACCEPT_CONSENT, isAccpeted);
    }
}

// make sure ready by timeout
export class ScreenBusUtils {
    // send GOOGLE Analytics
    static sendGAPage(page) {
        setTimeout(() => $emit(EventBusName.GA_SEND_PAGE, page), 0);
    }

    // send quick segment product
    static sendABSegmentPage(productId) {
        ScreenBusUtils.sendGAPage(`${routeName.NEW_SEGMENT_PRODUCT_ID}/${productId}`);

        // tracking page and product
        SegmentBusTrackLanding.segment01ABTestingData({
            dev_input_data_captured: `AB Segment - Product ${productId}`,
        });
    }

    static changeSegmentProduct(productId) {
        $emit(EventBusName.UPDATE_EXACT_SEGMENT_PRODUCT_BY_SAID, productId);
    }

    static hideModalCommon() {
        $emit(EventBusName.HIDE_MODAL_COMMON);
    }

    static showLoading() {
        $emit(EventBusName.SHOW_LOADING_QUICK, true);
    }

    static showLoadingLazy(delay = 100) {
        setTimeout(() => ScreenBusUtils.showLoading(), delay);
    }

    static hideLoading() {
        $emit(EventBusName.SHOW_LOADING_QUICK);
    }

    static hideLoadingLazy(delay = 100) {
        setTimeout(() => ScreenBusUtils.hideLoading(), delay);
    }

    static showLoadingInBackground() {
        $emit(EventBusName.SHOW_LOADING_IN_BACKGROUND, true);
    }

    static showLazyLoadingInBackground(delay = 100) {
        setTimeout(() => ScreenBusUtils.showLoadingInBackground(), delay);
    }

    static hideLoadingInBackground() {
        $emit(EventBusName.SHOW_LOADING_IN_BACKGROUND);
    }

    static hideLazyLoadingInBackground(delay) {
        setTimeout(() => ScreenBusUtils.hideLoadingInBackground(), delay);
    }

    static setMessageLoading(message) {
        setTimeout(() => $emit(EventBusName.SHOW_LOADING_MESSAGE, message));
    }

    static showTruIDFormAddress(formData) {
        $emit(EventBusName.TRUID_ADDRESS_MISSING, formData);

        ScreenBusUtils.sendGAPage(routeName.TRUID_ADDRESS_SUPPLEMENT);
        SegmentBusTrackTruID.segment1500MissingAddressInputData();
    }

    static showTruIDFormSalaryDate() {
        $emit(EventBusName.TRUID_SALARY_DATE_MISSING);
        SegmentBusTrackTruID.segment1500MissingSalaryPopupData();
    }

    /** SEND truidResumeStatus = G_PROFILE_INCOME_TYPE */
    static showModalTruIDGProfileType(declineReasonDetails = {}) {
        $emit(EventBusName.TRUID_NOTIFY_G_PROFILE_INCOME_TYPE, declineReasonDetails);
    }

    static saveDeclineStatus(statusCode, declineReasonDetails) {
        $emit(EventBusName.TRUID_SAVE_DELINCE_CODE, statusCode, declineReasonDetails);
    }

    static clearDeclineStatus() {
        $emit(EventBusName.TRUID_SAVE_DELINCE_CODE);
    }

    static showErrorCode(errorCode = ErrorCode.GENERIC_ERROR) {
        if (Array.isArray(errorCode)) {
            $emit(EventBusName.NOTIFY_ERROR, errorCode);
            return;
        }
        $emit(EventBusName.NOTIFY_ERROR, [{ errorCode }]);
    }

    static moveScreen(page) {
        $emit(EventBusName.MOVE_TO_SCREEN, page);
    }

    static gotoResume(loanApplication) {
        ScreenBusUtils.moveScreen(`${routeName.HONE}?loanApplication=${loanApplication}`);
    }

    static verifyResumeLoanApplicationBySaID() {
        $emit(EventBusName.VERIFY_LOAN_APPLICATION_SAID);
    }

    static fetchAffordabilityAfterUpdateMissing(missingStatus) {
        $emit(EventBusName.FETCH_TRUID_MISSING_AFFORDABILITY, missingStatus);
    }

    static fetchAffordabilityIfSprintHive() {
        $emit(EventBusName.FETCH_SPRINT_HIVE_AFFORDABILITY);
    }

    // screen moving
    static goHome(timeout = 0) {
        setTimeout(() => $emit(EventBusName.NOTIFY_CLEAR_GO_HOME), timeout);
    }

    static gotoScreenStart() {
        CommonBusUtils.setAcceptConsents(true);
        FacialBusUtils.setupSDKFacial();
        ScreenBusUtils.moveScreen(routeName.START);
    }

    static gotoScreenPhone() {
        ScreenBusUtils.moveScreen(routeName.PHONE);
    }

    static gotoScreenOtp(usBus) {
        const func = usBus ? ScreenBusUtils.moveScreen : push;
        return func(routeName.OTP);
    }

    static gotoScreenLoanAmount(usBus) {
        const func = usBus ? ScreenBusUtils.moveScreen : push;
        return func(routeName.LOAN_AMOUT);
    }

    static gotoScreenLoanAmountEdit() {
        ScreenBusUtils.moveScreen(`${routeName.LOAN_AMOUT}/edit`);
    }

    static gotoScreenLoanPurpose(isEdit) {
        const endPoint = isEdit ? '/edit' : '';
        ScreenBusUtils.moveScreen(`${routeName.LOAN_PURPOSE}${endPoint}`);
    }

    static gotoScreenUser() {
        ScreenBusUtils.moveScreen(routeName.USER_INFO);
    }

    static gotoScreenUserEdit() {
        ScreenBusUtils.moveScreen(`${routeName.USER_INFO}/edit`);
    }

    static gotoScreenFinancialInfomation(isEdit) {
        ScreenBusUtils.moveScreen(`${routeName.FINANCIAL_INFO}${isEdit ? '/edit' : ''}`);
    }

    static gotoScreenConfirmation() {
        ScreenBusUtils.moveScreen(routeName.CONFIRMATION);
    }

    static gotoScreenLimitOffers() {
        ScreenBusUtils.moveScreen(routeName.LIMIT_OFFERS);
    }

    static gotoScreenLowerOffersOfDE() {
        ScreenBusUtils.moveScreen(routeName.LOWER_OFFERS);
    }

    static gotoScreenOffers() {
        ScreenBusUtils.moveScreen(routeName.OFFERS);
    }

    static gotoScreenUpsellOffers() {
        ScreenBusUtils.moveScreen(routeName.UPSELL_OFFERS);
    }

    static gotoScreenConditionSalaryOffer() {
        ScreenBusUtils.moveScreen(routeName.TYME_BANK_OFFERS);
    }

    static gotoScreenConditionSalaryOfferApproved() {
        ScreenBusUtils.moveScreen(routeName.TYME_BANK_OFFERS_APPROVED);
    }

    static gotoScreenPackage() {
        ScreenBusUtils.moveScreen(routeName.PACKAGE);
    }

    static gotoScreenIntroTruID() {
        ScreenBusUtils.moveScreen(routeName.TRUID_INTRO);
    }

    static gotoScreenIntroSprintHive() {
        ScreenBusUtils.moveScreen(routeName.INTRO_SPRINTHIDE);
    }

    static gotoScreenTruID() {
        ScreenBusUtils.moveScreen(routeName.TRUID);
    }

    static gotoScreenLoanAgreement() {
        ScreenBusUtils.moveScreen(routeName.AGREEMENT);
    }

    static gotoScreenDebicheck() {
        ScreenBusUtils.moveScreen(routeName.DEBICHECK);
    }

    static gotoScreenIntroSelfie() {
        ScreenBusUtils.moveScreen(routeName.FACE_INTRO_SELFIE);
    }

    static gotoScreenSelfie() {
        ScreenBusUtils.moveScreen(routeName.FACE_SELFIE);
    }

    static gotoScreenSelfieResults() {
        ScreenBusUtils.moveScreen(routeName.FACE_SELFIE_RESULTS);
    }

    static gotoScreenDeclinedOffers(loanApplicationNumber, usBus) {
        const func = usBus ? ScreenBusUtils.moveScreen : push;
        return func(`${routeName.OFFER_DECLINED}/${loanApplicationNumber}`);
    }

    // browser event
    // backbrowser otp
    static backBrowserScreenResumeOtp(stepsInputData = {}) {
        // stepsInputDataReducer
        if (stepsInputData.said && stepsInputData.phoneNumber) {
            ScreenBusUtils.gotoScreenPhone();
            return;
        }
        ScreenBusUtils.goHome();
    }

    static notifyConfirmScreenLeave(handleOK, handleClose) {
        $emit(EventBusName.NOTIFY_CONFIRM_BEFORE_LEAVE, handleOK, handleClose);
    }

    // check move screen after resume
    static redirectScreenAfterResumeByLoanStatus({
        loanApplicationStatus,
        usBus,
        isEFProfile,
        loanApplicationNumber,
        upsellStatus,
        declinceCode,
    }) {
        const {
            APPROVED,
            CONDITIONALLY_APPROVED,
            CONDITIONALLY_APPROVED_COLLECTION_RECEIVED,
            CONTRACT_SIGNED,
            FUNDED,
        } = DecisioningResult.loanApplicationStatus;
        const func = usBus ? ScreenBusUtils.moveScreen : push;

        if (loanApplicationStatus === APPROVED) {
            return func(isEFProfile ? routeName.TYME_BANK_OFFERS_APPROVED : routeName.PACKAGE);
        }

        if (loanApplicationStatus === CONDITIONALLY_APPROVED ||
            loanApplicationStatus === CONDITIONALLY_APPROVED_COLLECTION_RECEIVED) {
            if (UpsellingResultStatus.YES[upsellStatus]) {
                return func(routeName.UPSELL_OFFERS);
            }
            return func(isEFProfile ? routeName.TYME_BANK_OFFERS : routeName.OFFERS);
        }

        if (loanApplicationStatus === CONTRACT_SIGNED) {
            return func(routeName.DEBICHECK);
        }

        if (loanApplicationStatus === FUNDED) {
            return func(routeName.FUNDED);
        }

        if (!loanApplicationNumber) {
            return func(routeName.RESUME);
        }

        SegmentBusTrackCode.tracking(declinceCode);
        return ScreenBusUtils.gotoScreenDeclinedOffers(loanApplicationNumber, true);
    }
}

export class TruIDBusUtils {
    static showTruIDCancel() {
        setTimeout(() => $emit(EventBusName.TRUID_NOTIFY_ACTION, { cancel: true }));
    }

    static showTruIDTimeout() {
        setTimeout(() => $emit(EventBusName.TRUID_NOTIFY_ACTION, { timeout: true }));
    }

    static showTruIDError() {
        setTimeout(() => $emit(EventBusName.TRUID_NOTIFY_ACTION, { error: true }));
    }

    static refreshURLTruIDScreen() {
        $emit(EventBusName.TRUID_NOTIFY_REFRESH);
    }

    static refreshTruIDIFrame() {
        $emit(EventBusName.TRUID_NORIFY_RELOAD_IFRAME);
    }

    static notifyResume(resumeData) {
        $emit(EventBusName.TRUID_NOTIFY_RESUME_URL, resumeData);
    }
}

export class MandateUtils {
    static showSuccess() {
        $emit(EventBusName.MANDATE_NOTIFY_CONFIRM);
    }

    static showError(infoData) {
        $emit(EventBusName.MANDATE_NOTIFY_ERROR, infoData);
    }
}

export class FacialBusUtils {
    static refreshFacialSession(isRefreshSession, callback) {
        $emit(EventBusName.FACETEC_REFRESH_API_SESSION, isRefreshSession, callback);
    }

    static refreshFacialResults() {
        $emit(EventBusName.FACTECT_REFRESH_API_FETCHING);
    }

    static showError(errorCode, callback, message) {
        $emit(EventBusName.FACETEC_NOTIFY_ERROR, errorCode, callback, message);
    }

    static showSelfieSuccess() {
        $emit(EventBusName.FACETEC_NOTIFY_SUCCESS);
    }

    static showGeneric(callback) {
        FacialBusUtils.showError(ErrorCode.FACIAL_GENERIC, callback);
    }

    static showErrorConfig(callback, message) {
        FacialBusUtils.showError(ErrorCode.FACIAL_ERROR_CONFIG, callback, message);
    }

    static showTimeout() {
        $emit(EventBusName.FACETEC_NOTIFY_TIMEOUT);
        SegmentBusTrackFacials.segment2100FacialVerificationTimeoutErrorData();
    }

    static showNoSupportCamera() {
        $emit(EventBusName.MEDIA_WEBCAM_NO_EXIST);
        SegmentBusTrackFacials.segment2100FacialVerificationNoCameraErrorData();
    }

    static showBlockCamera() {
        $emit(EventBusName.MEDIA_WEBCAM_BLOCK);
        SegmentBusTrackFacials.segment2100FacialVerificationNoCameraAccessData();
    }

    static askCamera() {
        $emit(EventBusName.FACETEC_ASK_CAMERA);
        SegmentBusTrackFacials.segment2100FacialVerificationRequestCameraData();
    }

    static startSDKFacial(onComplete) {
        $emit(EventBusName.FACTECT_SDK_START, onComplete);
    }

    static initSDKFacial(sdkData) {
        return new Promise((resolve, reject) => {
            setTimeout(() => $emit(EventBusName.FACTECT_SDK_INTITAL, { sdkData, resolve, reject }));
        });
    }

    static setupSDKFacial() {
        setTimeout(() => $emit(EventBusName.FACTECT_SDK_SETUP));
    }

    static showSDKError(errorCode, isSDK) {
        $emit(EventBusName.FACTECT_SDK_ERROR, errorCode, isSDK);
    }
}

export class UpsellBusUtils {
    static EventBusName = {
        HANDLE_OK: 'UPSELL_BACK_HANDLE_OK',
    }

    static handleOKBackBrowser() {
        $emit(UpsellBusUtils.EventBusName.HANDLE_OK);
    }
}

// optimize later
export function canUseEventBus({ uniqueId, seconds = timeCached }) {
    let canUsed = true; // always
    const oldTime = ingoreCached.get(uniqueId);
    if (oldTime) {
        const diff = (new Date().getTime() - oldTime) / 1000;
        canUsed = diff >= seconds;
    }
    ingoreCached.set(uniqueId, new Date().getTime());
    return canUsed;
}
