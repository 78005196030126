/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fetchFulfillmentResults } from '../actions';
import { DecisioningResult, ErrorCode } from '../constants';

import AccordionSimple from '../components/Common/AccordionSimple';
import ActionSteps from '../components/Common/ActionSteps';
import HelpTip from '../components/Common/HelpTip';
import LabelTitle from '../components/Common/LabelTitle';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import { MovingFadeIn } from '../components/Common/AnimateText';
import { FlexColumnCenter } from '../components/Common/FlexCenter';
import PLButton from '../components/Common/PLButton';

import { MandateUtils } from '../hooks/useEventBus';
import useHandleResendMandes from '../hooks/debicheck/useHandleResendMandes';
import { useResendDebit } from '../services/useService';
import { SegmentBusTrackFinal } from '../segment-bus/SegmentBusTrackFinal';

import { confirmStepData, debicheckStepData } from '../assets/data/debicheck-step-data';

const DebiCheck = ({
    // eslint-disable-next-line no-shadow
    fulfillmentResults,
    loanContractInfo,
    verifyLoanApplicationData,
    loanSubmission,
    // eslint-disable-next-line no-shadow
    fetchFulfillmentResults,
    otp,
}) => {
    const [results, setResults] = useState();
    const [disabledResend, setDisabledResend] = useState(false);

    useHandleResendMandes();

    const loanApplicationNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber') ||
        _.get(loanSubmission, 'loanApplicationNumber') ||
        _.get(fulfillmentResults, 'loanReferenceNumber');

    const {
        data, errors, loading, onPost,
    } = useResendDebit({
        said: _.get(loanContractInfo, 'customerInfo.said'),
        stepUpToken: otp.stepUpToken,
        loanApplicationNumber,
    });

    const isMandateDone = _.get(data, 'loanApplicationNumber');

    useEffect(() => {
        if (loanApplicationNumber) {
            const payload = {
                stepUpToken: otp.stepUpToken,
                loanReferenceNumber: loanApplicationNumber,
            };
            fetchFulfillmentResults(payload);
        }
        SegmentBusTrackFinal.segment2200LoanSuccessScreenData();
    }, []);

    // handler error
    useEffect(() => {
        if (loading) {
            return;
        }

        // show error
        if (errors) {
            const { errorCode } = _.get(errors, 'response.errors[0]', {});
            setDisabledResend(errorCode !== ErrorCode.MANDATE_SEND_FAILED);
            MandateUtils.showError(errorCode);
            return;
        }

        if (isMandateDone) {
            setDisabledResend(true);
            MandateUtils.showSuccess();
        }
    }, [data, loading, errors]);

    useEffect(() => {
        setResults(fulfillmentResults);
    }, [fulfillmentResults && fulfillmentResults.loanReferenceNumber]);

    const dataDebicheck = _.get(results, 'notPassConditionToDisburse', [])
        .filter(item => item.condition === DecisioningResult.loanApplicationStatus.DEBICHECK_ACCEPTED);

    const listContext = _.get(dataDebicheck, '[0].details.context', []);
    const expiredDate = _.get(dataDebicheck, '[0].details.guideline');

    const confirmStep = confirmStepData(listContext);

    return (
        <div id="debicheck">
            <div className="container pl-container-page">
                <LabelLoanApplication />

                <MovingFadeIn>
                    <FlexColumnCenter className="container py-3">
                        <LabelTitle className="text-center">
                            Last step to get your funds: Confirm your DebiCheck debit order
                        </LabelTitle>
                    </FlexColumnCenter>
                </MovingFadeIn>

                <FlexColumnCenter className="container debi-confirm">
                    {
                        listContext.length > 0 &&
                        <ActionSteps
                            className="col-md-10 col-sm-12"
                            listSteps={confirmStep}
                            vertical
                            noLinkLine
                            disabledPoint
                        />
                    }
                </FlexColumnCenter>

                {fulfillmentResults.isResendMandate &&
                    <MovingFadeIn duration="4s">
                        <FlexColumnCenter className="pt-5 pb-3">
                            <div className="text-center mb-2">If the debit order we sent you has expired, or you can't locate it, we can resend you another debit order:</div>
                            <PLButton
                                disabled={disabledResend || !fulfillmentResults.isResendMandate}
                                onClick={() => {
                                    onPost();
                                    setDisabledResend(true);
                                }}
                            >
                                {!isMandateDone ? 'Resend debit order' : 'Done'}
                            </PLButton>
                        </FlexColumnCenter>
                    </MovingFadeIn>
                }
            </div>

            <div className="section">
                <FlexColumnCenter className="container pl-container-page">
                    <AccordionSimple
                        title="How to easily manage your loan on the TymeBank app after we disburse the funds"
                        scrollMeIfOpen
                    >
                        <ActionSteps className="debicheck-steps" listSteps={debicheckStepData} />
                    </AccordionSimple>

                    {expiredDate &&
                        <HelpTip>
                            {expiredDate}
                        </HelpTip>}
                </FlexColumnCenter>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    fulfillmentResults: state.fulfillmentResults.result || {},
    loanSubmission: state.loanSubmission,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    loanContractInfo: state.loanContractInfo.loanContractInfo || {},
    otp: state.otp,
});

export default connect(
    mapStateToProps, { fetchFulfillmentResults },
)(DebiCheck);
