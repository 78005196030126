/**
 SegmentPage = {
    "type": "page",
    "name": "Personal Loan",
    "properties": {
      "title": "Personal Loan | Landing Page",
      "url": "http://www.example.com"
      ...
    }
  }

SegmentEvent = {
    segment-base-template-data.js
}
*/
import { $emit } from 'event-bus-e2z';
import useEventBus from './useEventBus';

export const SEGMENT_EVENT = {
    SEGMENT_READY: ' SEGMENT_READY',
    SEND_EVENT_DATA: 'SEGMENT_SEND_EVENT_DATA',
    SEND_PAGE_DATA: 'SEGMENT_SEND_PAGE_DATA',
    SEND_EVENT_TRACKING_FROM_CLIENT: 'SEND_EVENT_TRACKING_FROM_CLIENT',
};

/**
 * Returns an object with two functions: `ngheSegmentEvent` and `ngheSegmentPage`.
 * The `ngheSegmentEvent` function sends segment page data to the Segment analytics service.
 * The `ngheSegmentPage` function sends segment event data to the Segment analytics service.
 *
 * @return {Object} An object with two functions: `ngheSegmentEvent` and `ngheSegmentPage`.
 */
export const useSegmentEventBus = ({ plAnalytics, loanApplicationNumber }) => {
    const ngheSegmentPage = ({
        name,
        title,
        path,
        url,
        resolve,
    }) => {
        plAnalytics.page({
            name,
            title,
            path,
            url,
            loan_application_number: loanApplicationNumber || '',
        });
        resolve(true);
    };

    const ngheSegmentEvent = ({ segmentEventData, resolve }) => {
        const { event, ...props } = segmentEventData;
        // eslint-disable-next-line camelcase
        const loan_application_number = segmentEventData.loanApplicationNumber || loanApplicationNumber || '';

        if (window.Analytics) {
            plAnalytics.track(event, { ...props, loan_application_number });
            resolve(true);
            return;
        }

        // lazy params
        setTimeout(
            () => {
                plAnalytics.track(event, { ...props, loan_application_number });
                resolve(true);
            },
            250,
        );
    };

    useEventBus(SEGMENT_EVENT.SEND_EVENT_DATA, ngheSegmentEvent);
    useEventBus(SEGMENT_EVENT.SEND_PAGE_DATA, ngheSegmentPage);

    return { ngheSegmentEvent, ngheSegmentPage };
};

/**
 * Class representing a Segment Event Bus.
 * This class provides static methods to send segment events and segment page data using an event bus.
 * It utilizes the $emit function from 'event-bus-e2z' to emit the events.
 */
export class WrapSegmentBusUtils {
    static segmentReady() {
        $emit(SEGMENT_EVENT.SEGMENT_READY, true);
    }

    static sendSegmentEvent(segmentEventData) {
        return new Promise((resolve) => {
            $emit(SEGMENT_EVENT.SEND_EVENT_DATA, { segmentEventData, resolve });
        });
    }

    /*
    static sendSegmentPage({
        name,
        title,
        path,
        url,
    }) {
        return new Promise((resolve) => {
            setTimeout(() => $emit(SEGMENT_EVENT.SEND_PAGE_DATA, {
                name,
                title,
                path,
                url,
                resolve,
            }));
        });
    }
    */

    static sendSegmentEventTrackingFromClient(eventName, params) {
        return new Promise((resolve) => {
            setTimeout(() => $emit(SEGMENT_EVENT.SEND_EVENT_TRACKING_FROM_CLIENT, { eventName, params, resolve }), 10);
        });
    }
}
