import { FaceTecSDK } from 'react-facetec-sdk/build/core/FaceTecSDK.js/FaceTecSDK';
import { buildPreSignedBody } from './FacialEncryption';

import { FacialBusUtils, ScreenBusUtils } from '../../hooks/useEventBus';
import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';

export class LivenessCheckProcessor {
    latestNetworkRequest = new XMLHttpRequest();
    latestSessionResult;
    success;
    publicEncryptionKey;
    preSignedURL;
    onComplete;
    selfieResult;
    faceScanResultCallback;

    constructor(preSignedURL, publicEncryptionKey, onComplete, sessionToken = '') {
        this.success = false;
        this.onComplete = onComplete;
        this.latestSessionResult = null;
        this.preSignedURL = preSignedURL;
        this.publicEncryptionKey = publicEncryptionKey;
        this.selfieResult = null;

        //
        // Part 1:  Starting the FaceTec Session
        // Required parameters:
        // - FaceTecFaceScanProcessor:  A class that implements FaceTecFaceScanProcessor, which handles the FaceScan when the User completes a Session.  In this example, "this" implements the class.
        // - sessionToken:  A valid Session Token you just created by calling your API to get a Session Token from the Server SDK.
        //
        // console.log('face-step 2 --', sessionToken);
        new FaceTecSDK.FaceTecSession(this, sessionToken);
    }

    //
    // Part 2:  Handling the Result of a FaceScan
    async processSessionResultWhileFaceTecSDKWaits(sessionResult, faceScanResultCallback) {
        // console.log('face-step 3', sessionResult);
        // Save the current sessionResult
        this.latestSessionResult = sessionResult;
        this.faceScanResultCallback = faceScanResultCallback;

        //
        // Part 3:  Handles early exit scenarios where there is no FaceScan to handle -- i.e. User Cancellation, Timeouts, etc.
        if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
            this.latestNetworkRequest.abort();
            faceScanResultCallback.cancel();

            // handle error
            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.Timeout,
                    FaceTecSDK.FaceTecSessionStatus.UnknownInternalError,
                ].includes(sessionResult.status)
            ) {
                FacialBusUtils.showTimeout();
                // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                return;
            }

            // handle error
            if (sessionResult.status === FaceTecSDK.FaceTecSessionStatus.ContextSwitch) {
                FacialBusUtils.showSDKError(sessionResult.status);
                return;
            }

            // cancel handle
            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.UserCancelled,
                    FaceTecSDK.FaceTecSessionStatus.ProgrammaticallyCancelled,
                    FaceTecSDK.FaceTecSessionStatus.OrientationChangeDuringSession,
                    FaceTecSDK.FaceTecSessionStatus.LandscapeModeNotAllowed,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFromNewUserGuidance,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFromRetryGuidance,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledWhenAttemptingToGetCameraPermissions,
                    FaceTecSDK.FaceTecSessionStatus.LockedOut,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledViaClickableReadyScreenSubtext,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFullScreenMode,
                ].includes(sessionResult.status)
            ) {
                ScreenBusUtils.notifyConfirmScreenLeave();
                // more reason
                if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.UserCancelled) {
                    SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                }
                return;
            }

            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.CameraNotEnabled,
                    FaceTecSDK.FaceTecSessionStatus.CameraNotRunning,
                ].includes(sessionResult.status)
            ) {
                FacialBusUtils.showBlockCamera();
                // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                return;
            }

            FacialBusUtils.showGeneric();
            SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
            return;
        }

        // we cannot detect faces
        if (!sessionResult.faceScan) {
            this.latestNetworkRequest.abort();
            faceScanResultCallback.cancel();
            FacialBusUtils.showGeneric(() => FacialBusUtils.refreshFacialSession(true));
            // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
            return;
        }

        // IMPORTANT:  FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully DOES NOT mean the Liveness Check was Successful.
        // It simply means the User completed the Session and a 3D FaceScan was created.  You still need to perform the Liveness Check on your Servers.

        //
        // Part 4:  Get essential data off the FaceTecSessionResult
        //
        // const body = {
        //   secret: "",
        //   faceMap: sessionResult.faceScan,
        //   auditTrailImage: sessionResult.auditTrail[0],
        //   sessionId: sessionResult.sessionId,
        //   preSignedURL: this.preSignedURL
        // };
        // console.log('facialScan', sessionResult);
        const parameters = await buildPreSignedBody(sessionResult, this.publicEncryptionKey);
        //
        // Part 5:  Make the Networking Call to Your Servers.  Below is just example code, you are free to customize based on how your own API works.
        //
        this.latestNetworkRequest = new XMLHttpRequest();
        this.latestNetworkRequest.open('PUT', this.preSignedURL);
        this.latestNetworkRequest.setRequestHeader('Content-Type', 'application/json');
        this.latestNetworkRequest.setRequestHeader('Access-Control-Allow-Origin', '*');
        this.latestNetworkRequest.setRequestHeader('x-amz-acl', 'bucket-owner-full-control');

        // this.latestNetworkRequest.setRequestHeader('X-Device-Key', FaceTecConfig.DeviceKeyIdentifier);
        // this.latestNetworkRequest.setRequestHeader(
        //   'X-User-Agent',
        //   FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId),
        // );

        this.latestNetworkRequest.crossDomain = true;
        // this.latestNetworkRequest.withCredentials = true;

        this.latestNetworkRequest.onreadystatechange = () => {
            SegmentBusTrackFacials.segment2100FacialVerificationCaptureSelfieData();
            //
            // Part 6:  In our Sample, we evaluate a boolean response and treat true as was successfully processed and should proceed to next step,
            // and handle all other responses by cancelling out.
            // You may have different paradigms in your own API and are free to customize based on these.
            //
            // console.log('face-step 4 -- ', this.latestNetworkRequest.readyState);
            if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
                try {
                    const responseJSON = JSON.parse(this.latestNetworkRequest.responseText);
                    // eslint-disable-next-line prefer-destructuring
                    const scanResultBlob = responseJSON.scanResultBlob;

                    // In v9.2.0+, we key off a new property called wasProcessed to determine if we successfully processed the Session result on the Server.
                    // Device SDK UI flow is now driven by the proceedToNextStep function, which should receive the scanResultBlob from the Server SDK response.
                    if (responseJSON.wasProcessed) {
                        // Demonstrates dynamically setting the Success Screen Message.
                        FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
                            'Liveness\nConfirmed',
                        );

                        // In v9.2.0+, simply pass in scanResultBlob to the proceedToNextStep function to advance the User flow.
                        // scanResultBlob is a proprietary, encrypted blob that controls the logic for what happens next for the User.
                        faceScanResultCallback.proceedToNextStep(scanResultBlob);
                        // console.log('face-step 4 -- was processed');
                    } else {
                        // CASE:  UNEXPECTED response from API.  Our Sample Code keys off a wasProcessed boolean on the root of the JSON object --> You define your own API contracts with yourself and may choose to do something different here based on the error.
                        // console.log('Unexpected API response, cancelling out.');
                        faceScanResultCallback.cancel();
                        // console.log('face-step 4 -- unexpected API response');
                    }
                } catch (ex) {
                    // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself and may choose to do something different here based on the error.  Solid server-side code should ensure you don't get to this case.
                    // console.log('Exception while handling API response, cancelling out.');
                    faceScanResultCallback.cancel();
                }
            }
        };

        this.latestNetworkRequest.onerror = () => {
            // console.log('error -- unexpected API response');
            // CASE:  Network Request itself is erroring --> You define your own API contracts with yourself and may choose to do something different here based on the error.
            // console.log('XHR error, cancelling.');
            faceScanResultCallback.cancel();
        };

        //
        // Part 7:  Demonstrates updating the Progress Bar based on the progress event.
        //
        // console.log('face-step 5 -- upload onprogress');
        this.latestNetworkRequest.upload.onprogress = (event) => {
            const progress = event.loaded / event.total;
            // console.log('face-step 5 -- upload % ', progress);
            faceScanResultCallback.uploadProgress(progress);
        };

        //
        // Part 8:  Actually send the request.
        //
        const jsonStringToUpload = JSON.stringify(parameters);
        // console.log('face-step 6 -- send request');
        this.latestNetworkRequest.send(jsonStringToUpload);

        //
        // Part 9:  For better UX, update the User if the upload is taking a while.  You are free to customize and enhance this behavior to your liking.
        //
        setTimeout(() => {
            // console.log('face-step 7 -- setTimeout');
            if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
                // console.log('face-step 8 -- done');
                return;
            }
            faceScanResultCallback.uploadMessageOverride('Still Uploading...');
            // console.log('face-step 8 -- Still Uploading...');
        }, 6000);
    }

    //
    // Part 10:  This function gets called after the FaceTec SDK is completely done.  There are no parameters because you have already been passed all data in the processSessionWhileFaceTecSDKWaits function and have already handled all of your own results.
    //
    onFaceTecSDKCompletelyDone = async () => {
        // console.log('face-step 9 -- onFaceTecSDKCompletelyDone');
        //
        // Calling a custom function on the Sample App Controller is done for demonstration purposes to show you that here is where you get control back from the FaceTec SDK.
        //
        this.success = (this.latestSessionResult || {}).status === FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully;
        this.onComplete(this.latestSessionResult, this.success, this.latestNetworkRequest.status);
    };

    //
    // DEVELOPER NOTE:  This public convenience method is for demonstration purposes only so the Sample App can get information about what is happening in the processor.
    // In your code, you may not even want or need to do this.
    //
    isSuccess = () => this.success;

    clearStop = () => {
        this.latestNetworkRequest && this.latestNetworkRequest.abort();
        this.faceScanResultCallback && this.faceScanResultCallback.cancel();
        this.faceScanResultCallback = undefined;
    }
}
