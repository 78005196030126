import { useEffect, useState } from 'react';
import _ from 'lodash';

import useEventBus, {
    canUseEventBus,
    EventBusName,
    FacialBusUtils,
    ScreenBusUtils,
} from '../../hooks/useEventBus';
import { useInitialFacialInfo } from '../../services/useFacialSDKService';

const useFacialRefreshSession = ({
    stepsInputData,
    customerInfo,
    stepUpToken,
    saveStepsInput,
    loanApplicationNumber,
}) => {
    const { facialInfoData: facialInfo = {} } = stepsInputData || {};
    const [refeshPooling, setRefreshPooling] = useState({
        status: false,
        callback: undefined,
    });

    const {
        onPost, rePost, data: facialInfoData, errors, loading,
    } = useInitialFacialInfo({
        stepUpToken,
        session: facialInfo.sessionToken,
        phoneNumber: stepsInputData.phoneNumber || customerInfo.phone,
        said: stepsInputData.said || customerInfo.said,
        loanApplicationNumber,
    });

    useEventBus(EventBusName.FACETEC_REFRESH_API_SESSION, (isRefreshSession, callback) => {
        if (!canUseEventBus({
            uniqueId: EventBusName.FACETEC_REFRESH_API_SESSION,
            seconds: 1,
        })) {
            return;
        }

        setRefreshPooling({
            status: true,
            callback,
        });

        if (!isRefreshSession) {
            rePost(facialInfo.sessionToken);
            return;
        }
        saveStepsInput({ facialInfoData: undefined });
        rePost();
    });

    useEffect(() => {
        if (errors) {
            saveStepsInput({ facialInfoData: undefined });
            setRefreshPooling({});

            const errorMessage = errors.response || _.get(errors, 'cause.message');
            refeshPooling.status && FacialBusUtils.showErrorConfig(() => {
                ScreenBusUtils.showLoadingLazy();
                rePost();
            }, errorMessage);
        }
    }, [errors]);

    useEffect(() => {
        if (facialInfoData) {
            saveStepsInput({
                facialInfoData: {
                    ...facialInfoData,
                    sessionToken: facialInfoData.sessionToken || facialInfoData.session,
                },
            });

            if (refeshPooling.callback) {
                refeshPooling.callback();
            }
            setRefreshPooling({});
        }
    }, [facialInfoData, refeshPooling.callback]);

    return {
        onPost, rePost, facialInfoData, errors, loading,
    };
};

export default useFacialRefreshSession;
