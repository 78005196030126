/*
+ Should mirgate framework:
+  - Using hook for clean code.
+  - Easy apply.
+  - Limited redux, save memory.
+  - Single request
*/
import { useEffect, useState } from 'react';
import { $emit } from 'event-bus-e2z';
import axios from 'axios';

import config from '../config';
import HttpClient from './HttpClient';
import { EventBusName, ScreenBusUtils } from '../hooks/useEventBus';

export const API_HOST_AND_CONTEXT = config.host + config.apiService.contextPath;
export const API_HOST_AND_LDS_CONTEXT = config.host + config.apiService.ldsContextPath;
export const API_HOST_AND_LCS_CONTEXT = config.host + config.apiService.extLcsContextPath;

// eslint-disable-next-line prefer-destructuring
const CancelToken = axios.CancelToken;

export const usePost = ({
  url,
  bodyData,
  headers,
  noLockScreen = false,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState(null);

  const refetchParam = ({
    disabledLocked = noLockScreen,
    newData = {},
  } = {}) => {
    !disabledLocked && ScreenBusUtils.showLoading();
    $emit(EventBusName.SHOW_LOADING_IN_BACKGROUND, true);
    setData(null);
    setError(null);
    setLoading(true);
    HttpClient
      .axiosPost({
        endPoint: url,
        data: { ...bodyData, ...(newData || {}) },
        headers,
      })
      .then((res) => {
        setData(res.data || res.status);
      })
      .catch(cause => {
        if (cause.response) {
          setError({
            response: cause.response.data,
            status: cause.response.status,
            isError: true,
            cause,
          });
        } else {
          setError({
            isError: true,
            cause,
          });
        }
      })
      .finally(() => {
        setLoading(false);
        !disabledLocked && ScreenBusUtils.hideLoading();
        $emit(EventBusName.SHOW_LOADING_IN_BACKGROUND);
      });
  };

  const refetch = (disabledLocked = noLockScreen) => {
    refetchParam({ disabledLocked });
  };

  return {
    data, loading, errors, onPost: refetch, onPostData: refetchParam,
  };
};

export const useGet = ({
  url,
  paramData = {},
  headers = {},
  noLockScreen = false,
  fetchMounted = false,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState(null);

  let source = CancelToken.source();

  const refetchParam = ({
    newUrl = url,
    newParams = {},
    mergeParams = false,
    disabledLocked = noLockScreen,
  }) => {
    !disabledLocked && $emit(EventBusName.SHOW_LOADING_QUICK, true);
    setData(null);
    setError(null);
    setLoading(true);

    HttpClient
      .axiosGet({
        endPoint: newUrl,
        params: !mergeParams ? newParams : { ...paramData, ...newParams },
        headers,
        cancelToken: source.token,
      })
      .then((res) => {
        setData(res.data || res.status);
      })
      .catch(cause => {
        if (cause.response) {
          setError({
            response: cause.response.data,
            status: cause.response.status,
            isError: true,
            cause,
          });
        } else {
          setError({
            isError: true,
            cause,
          });
        }
      })
      .finally(() => {
        setLoading(false);
        !disabledLocked && $emit(EventBusName.SHOW_LOADING_QUICK);
      });
  };

  const cancelRequest = () => {
    source.cancel();
    source = CancelToken.source();
    !noLockScreen && $emit(EventBusName.SHOW_LOADING_QUICK);
  };

  const refetch = (disabledLocked = noLockScreen) => {
    refetchParam({ disabledLocked });
  };

  useEffect(() => {
    fetchMounted && url && refetch();
  }, [url]);
  return {
    data, loading, errors, refetch, refetchParam, cancelRequest,
  };
};

export const useResendDebit = ({ said, stepUpToken, loanApplicationNumber }) => {
  const url = `${API_HOST_AND_LCS_CONTEXT}/external/resend-mandate`;
  return usePost({
    url,
    headers: {
      'Step-Up-Token': stepUpToken,
      said,
    },
    bodyData: {
      loanApplicationNumber,
    },
  });
};
